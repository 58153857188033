<template>
    <div class="autocomplete relative">
        <input
            type="text"
            class="mt-1"
            :value="value"
            :disabled="disabled"
            :required="required"
            :placeholder="placeholder"
            autocomplete="none"
            :maxlength="maxlength"
            :class="[...bodyClass, { 'autocomplete-input-invalid': !isValid && !showList }]"
            @input="handleInput"
            @paste="handleInput"
            @focus="onFocus"
            @change="$emit('change')"
            @keyup.enter="onEnter"
        />

        <div v-show="isDisplayList" class="cinput-autocomplete__el" :class="[direction]">
            <div
                v-for="(item, i) in options"
                :key="`autocomplete_${i}`"
                class="cinput-autocomplete__item"
                @click="select(item)"
            >
                <slot :data="item">
                    {{ item }}
                </slot>
            </div>
        </div>

        <slot v-if="isDisplayList" name="afterlist" />
    </div>
</template>
<script>
import axios from 'axios';

export default {
    name: 'Autocomplete',
    emits: ['input', 'click', 'change'],
    props: {
        value: String,
        disabled: Boolean,
        required: Boolean,
        placeholder: String,
        bodyClass: {
            type: Array,
            default: () => [],
        },
        isValid: {
            type:Boolean,
            default: true,
        },
        endpoint: Function,
        filters: {
            type: Object,
            default: null,
        },
        params: {
            type: Object,
            default: null,
        },
        direction: {
            type: String,
            default: 'bottom',
        },
        maxlength: {
            type: String,
            default: '',
        },
        immediateEmptySearch: {
            type: Boolean,
            default: false,
        },
        onOptionsLoaded: {
            type: Function,
            default: null,
        },
        searchTriggerCharsThreshold: {
            type: Number,
            default: 3,
        },
        defaultOptions: {
            type: Array,
            default: null,
        },
        hideListOnEnter: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        filters(val, oldVal) {
            if (JSON.stringify(val) === JSON.stringify(oldVal)) {
                return;
            }

            this.showList = false;
            this.options = [];
        },
        params(val, oldVal) {
            if (JSON.stringify(val) === JSON.stringify(oldVal)) {
                return;
            }

            this.showList = false;
            this.options = [];
        },
    },
    data() {
        return {
            showList: false,
            options: [],
            timeoutOptions: null,
            prevRequestUrl: '',
        };
    },
    computed: {
        isDisplayList() {
            return this.showList && this.options && this.options.length;
        },
    },
    methods: {
        onEnter(event) {
            this.$emit('enter');

            if (this.hideListOnEnter) {
                event.target.blur();
                this.showList = false;
            }
        },
        onFocus() {
            this.$emit('focus');
            this.showList = true;
        },
        select(value) {
            this.$emit('click', value);
            this.showList = false;
        },
        handleInput(event) {
            this.$emit('input', event.target.value);
            clearTimeout(this.timeoutOptions);

            if (!this.endpoint && !this.defaultOptions) {
                return;
            }

            if (!this.endpoint && this.defaultOptions) {
                this.options = this.defaultOptions.filter(option => option.indexOf(event.target.value));

                return;
            }

            if (!this.showList || event.target.value.length < this.searchTriggerCharsThreshold) {
                this.options = [];

                return;
            }

            if (!this.getIsParamsValid(this.params) || !this.getIsParamsValid(this.filters)) {
                return;
            }

            this.timeoutOptions = setTimeout(() => this.getOptions(event.target.value), 800);
        },
        async getOptions(value) {
            if (!this.endpoint) {
                return;
            }

            let url = this.endpoint(value);
            const query = [this.getFilterParam(value), this.getQueryFilter()].filter(val => val).join('&');

            if (query?.length) {
                url += query;
            }

            const { data : { data } } = await axios.get(url);
            this.prevRequestUrl = url;
         
            if (this.onOptionsLoaded) {
                this.options = this.onOptionsLoaded(data) || data;

                return;
            }
         
            this.options = data;
        },
        async fetchOptions() {
            // for external calls only
            if (!this.prevRequestUrl) {
                return;
            }

            const { data : { data } } = await axios.get(this.prevRequestUrl);
         
            if (this.onOptionsLoaded) {
                this.options = this.onOptionsLoaded(data) || data;

                return;
            }
         
            this.options = data;
        },
        getIsParamsValid(params) {
            if (!params) {
                return true;
            }

            const keys = Object.keys(params);

            if (!keys?.length) {
                return true;
            }

            return keys.find(key => params[key] !== null && params[key] !== undefined);
        },
        getFilterParam() {
                const query = this.params
                    ? Object.keys(this.params).map(key => `${key}=${this.params[key]}`).join('&')
                    : '';

                return query.length ? `&${query}` : query;

        },
        getQueryFilter() {
                const query = this.filters
                    ? Object.keys(this.filters).map(key => `filter[${key}]=${this.filters[key]}`).join('&')
                    : '';

                return query.length ? `&${query}` : query;

        },
        outsideClickListener(event) {
            if (event.target !== this.$el && !this.$el.contains(event.target) && this.showList === true) {
                this.showList = false;
            }
        },
    },
    async mounted() {
        window.addEventListener('click', this.outsideClickListener);

        if (this.defaultOptions) {
            this.options = this.defaultOptions;
        }

        if (!this.immediateEmptySearch) {
            return;
        }

        this.getOptions();
    },
    beforeDestroy() {
        removeEventListener('click', this.outsideClickListener);
    },
}
</script>
<style lang="scss" scoped>
.autocomplete {
    // z-index: 1000;
    input {
        border-color: var(--color-border) !important;
    }
    .cinput-autocomplete {
        &__el { 
            position: absolute;
            left: 0;
            top: calc(100%);
            width: 100%;
            border: 1px solid #E6E6E6;
            background: white;
            z-index: 2;
            overflow-y: auto;
            max-height: calc(39px * 6);

            &.top {
                top: 0px;
                transform: translateY(-100%);
            }
        }

        &__item {
            font-size: 0.75rem;
            display: block;
            padding: 6px 12px;
            min-height: 2rem;
            line-height: 1.6;
            text-decoration: none;
            text-transform: none;
            vertical-align: middle;
            position: relative;
            cursor: pointer;

            &:hover {
                background-color: var(--brand-color) !important;
                color: white;
            }

        }
    }
    .autocomplete-input-invalid {
        @apply border-red-500 #{!important};
    }
}
</style>