export default {
    computed: {
        lockedFields() {
            return this.$store.getters['price-leadtime/lockedFields'];
        },
        currentTemplateComputed() {
            return this.$store.getters['price-leadtime/currentTemplate'];
        },
        isGodUser() {
            return this.$store.getters['auth/isGodUser'];
        },
        isTemplate() {
            const routeSplitted = this.$route.name.split('.');

            return  routeSplitted[0] === 'transport' && routeSplitted[2] === 'template';
        },
        isFormAccessable() {
            return [
                !this.isTemplate,
                this.isGodUser,
                Boolean(!this.isGodUser && this.currentTemplateComputed),
            ].some(val => val);
        },
    },
    methods: {
        getIsLockedField(key) {
            if (!this.isFormAccessable) {
                return true;
            }

            return Boolean(this.lockedFields[key]);
        },
    },
};