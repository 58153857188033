<template>
    <div class="booking-attachments">
        <div class="flex">
            <file-input :multiple="true" @update="addDocuments" class="mb-2" />
        </div>

        <div class="flex flex__align-center mt-4" v-for="(file, index) in attachments" :key="file.name">
            <svg-importer icon-name="icons/document-lines" /> {{ file.name }}
            <button @click="removeDocument(index)" class="ml-2">
                <svg-importer icon-name="icons/close" width="18" />
            </button>
        </div>
    </div>
</template>
<script>
import FileInput from '~/components/FileInput';

export default {
    name: 'PriceLeadTimeAttatchmentsComponent',
    components: { FileInput, },
    computed: {
        attachments() {
            return this.$store.state['price-leadtime'].attachments;
        },
    },
    methods: {
        removeDocument(index) {
            this.$store.commit('price-leadtime/REMOVE_ATTACHMENT', index);
        },
        addDocuments(files) {
            files.forEach(file => {
                if (this.attachments.find(
                    attachment => attachment.name === file.name && attachment.size === file.size && attachment.type === file.type)
                ) {
                    return;
                }

                this.$store.commit('price-leadtime/ADD_ATTACHMENT', file);
            });
        },
    },
}
</script>