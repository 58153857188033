<template>
    <div class="flex flex-wrap w-full">
        <div class="w-full">
            <card bodyClass="flex-wrap flex-col">
                <div
                    class="w-full mt-4 lower-section"
                    :class="{ 'flex flex-wrap': isTemplate }"
                >
                    <modal-field
                        v-for="(item, index) in keys"
                        :key="`delivery-terms_${index}`"
                        :label="item.label"
                        class="mb-4"
                        :class="[
                            isTemplate ? 'w-1/2 pr-4' : 'w-2/3',
                            { empty: item.required ? getIsEmptyField(data[item.key]) : false },
                        ]"
                        :required="item.required"
                    >
                        <autocomplete
                            v-if="isInstructionsField(item.key)"
                            v-model="data[item.key].instruction"
                            :endpoint="getInstructionsEndpoint"
                            :filters="getInstructionsFilter(item.key)"
                            :maxlength="getMaxLength(item.key)"
                            :immediate-empty-search="needsdDefaultPickupValue(item.key)"
                            :on-options-loaded="needsdDefaultPickupValue(item.key) ? onPickupInstructionsOptionsLoaded : null"
                            :disabled="getIsLockedField(instructionsMap.get(item.key))"
                            @input="updateInstructionValue(item.key, $event)"
                            @click="setInstructionValue(item.key, $event)"
                        >
                            <template v-slot="slotProps">
                                {{ slotProps.data.instruction }}
                            </template>
                        </autocomplete>

                        <div v-else class="mt-1">
                            <input
                                v-model="data[item.key]"
                                type="text"
                                autocomplete="none"
                                :disabled="!isFormAccessable"
                            />
                        </div>
                    </modal-field>
                    <attachments v-if="!isTemplate" />
                </div>
            </card>
        </div>
    </div>

</template>

<script>
import Autocomplete from '@/components/Autocomplete.vue';
import Attachments from '~/components/PriceLeadTime/Attachments.vue';
import IsTemplateMixin from '~/mixins/isTemplate.mixin';
import lockedMixin from './locked.mixin';

const instructionsMap = new Map([
    ['pickup_instructions', 'pickup_instruction'],
    ['delivery_instructions', 'delivery_instruction'],
]);

export default {
    name: "DeliveryTerms",
    mixins: [IsTemplateMixin, lockedMixin],
    components: { Autocomplete, Attachments },

    data() {
        return {
            instructions: {
                pickup_instructions: null,
                delivery_instructions: null,
            },
            instructionsMap,
        };
    },

    computed: {
        user() {
            return this.$store.getters['auth/user'];
        },

        data() {
            return this.$store.getters[`price-leadtime/delivery_terms`];
        },

        priceLeadTimeResult() {
            return this.$store.getters['price-leadtime/getPriceLeadTimeResult'];
        },

        keys() {
            return [
                {
                    label: 'Pickup instructions',
                    key: 'pickup_instructions',
                }, 
                {
                    label: 'Delivery instructions',
                    key: 'delivery_instructions',
                },
                {
                    label: `Senders' reference`,
                    key: 'senders_reference',
                    required: true,
                },
                {
                    label: `Recipients' reference`,
                    key: 'recipients_reference',
                }
            ];
        }
    },

    methods: {
        needsdDefaultPickupValue(key) {
            return (this.user.organization.key === 'SUZUKISE' || this.user.organization.key === 'VOLVO NO') && key === 'pickup_instructions';
        },
        getMaxLength(key) {
            if (key === 'pickup_instructions') {
                return '96';
            }

            if (key === 'delivery_instructions') {
                return '90';
            }

            return '';
        },
        isInstructionsField(name) {
            return ['pickup_instructions', 'delivery_instructions'].includes(name);
        },
        getInstructionsEndpoint(val) {
            if (!val) {
                return `${this.$apiUrl.consignments.bookingInstructions}?`; 
            }

            return `${this.$apiUrl.consignments.bookingInstructions}?filter[instruction]=${val}`;
        },
        getInstructionsFilter(key) {
            return {
                organization_id: this.user?.organization?.id,
                type: key === 'pickup_instructions' ? 'pickup' : 'delivery',
            };
        },
        updateInstructionValue(key, value) {
            if (!value || !this.instructions[key]) {
                this.instructions[key] = null;

                return;
            }

            if (this.instructions[key].instruction[0].toLowerCase() !== value[0].toLowerCase()) {
                this.instructions[key] = null;
            }
        },
        setInstructionValue(key, value) {
            this.instructions[key] = value;
            this.data[key].instruction = value.instruction;
        },
        onPickupInstructionsOptionsLoaded(options) {
            if (this.data.pickup_instructions) {
                return;
            }

            this.data.pickup_instructions = options.find(option => option.type === 'pickup')?.instruction || '';
        },
    },
}
</script>
