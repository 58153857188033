<template>
    <div>
        <div v-show="!loading" class="form-file-input">
            <label class="form-file-input__file">
                <input
                    type="file"
                    class="form-file-input__input"
                    :multiple="multiple"
                    @change="multiple ? handleFiles($event.target.files) : handleFile($event.target.files[0])" ref="fileInput"
                />

                <div class="btn-transparent flex align-center cursor-pointer">
                    <svg-importer icon-name="icons/document" class="mr-2" />
                    <span class="text-body">Add document</span>
                </div>
            </label>
        </div>

        <spinner v-show="loading" size="21px" title="loading..." class="mb-2 mt-2" />
    </div>
</template>

<script>
import axios from 'axios';
import Spinner from '~/components/Spinner';

export default {
    components: {
        Spinner,
    },

    props: {
        multiple: {
            type: Boolean,
            default: false,
        },
    },
    
    data() {
        return {
            file: null,
            name: '',
            loading: false,
        }
    },
    
    methods: {
        handleFile(file) {
            this.loading = true;

            if (!this.name) {
                this.name = file.name;
            }
            
            const image = file;
            this.file = file;
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = async () => {
                await this.getPreview()
            };
        },

        handleFiles(files) {
            this.loading = true;
            const filesLoaded = [];

            for(const file of files) {
                const image = file;
                const reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = async () => {
                    filesLoaded.push(image);

                    if (filesLoaded.length === files.length) {

                        this.$emit('update', filesLoaded);
                        this.loading = false;
                    }
                };
            }
        },

        async getPreview() {
            try {
                this.$emit('update', this.file);
            } catch(error) {
                const message = error?.response?.data?.errors.file?.join(',') || error?.response?.data?.message;

                this.$snotify.error(message);
            } finally {
                this.file = null;
                this.name = null;
                this.$refs.fileInput.value = '';
                this.loading = false;
            }
        }
    },
}
</script>

<style scoped lang="scss">
input[type="file"] {
    display: none;
}
</style>